var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"browse-tree"},[_c('div',{staticClass:"top"},[_c('div',{on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"el-icon-arrow-left",staticStyle:{"font-size":"20px"}}),_c('span',[_vm._v("返回")])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","height":"50px","align-items":"center","justify-content":"center"}},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.addGenealogyForm)?_c('span',{staticStyle:{"font-size":"12px","margin-top":"5px"}},[_vm._v(" 该族谱收录 "+_vm._s(_vm.addGenealogyForm.generationCount)+" 世，共计 "+_vm._s(_vm.addGenealogyForm.memberCount)+" 人 ")]):_vm._e()]),_c('van-icon',{staticStyle:{"font-size":"22px"},attrs:{"name":"search"},on:{"click":function($event){_vm.actionShow = true}}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"panelbody",staticClass:"panel-body"},[_c('div',{staticClass:"linwrap",on:{"touchstart":function($event){$event.preventDefault();return _vm.touchstart.apply(null, arguments)}}},[_c('div',{staticClass:"lineage-content",style:({
          transform:
            'scale(' +
            _vm.scaleValue +
            ') translate(' +
            _vm.XValue +
            'px,' +
            -_vm.YValue +
            'px)',
        })},[_c('div',{ref:"bodyRight",staticClass:"linshow"},[_c('div',{ref:"pnlCheckingBody",staticClass:"lleft",style:({
              transform:
                'translate(' + _vm.transformpx + 'px,' + _vm.transformpy + 'px)',
            })},[_vm._l((_vm.LineageData),function(items,indexs){return _c('div',{key:indexs,attrs:{"data-id":items.id}},[_c('div',{staticClass:"tree-node",class:{
                  setp:
                    (_vm.searchIndex == 1 && items.id == _vm.searchId) ||
                    (_vm.searchIndex == 2 && _vm.Include(items.id).bools),
                },style:({
                  position: 'absolute',
                  top: items.node.y - 186 + 186 + 'px',
                  left:
                    _vm.MapNodesfrom.width -
                    items.node.x -
                    _vm.defaultNodeSize.width -
                    (_vm.headerWidth + 7) +
                    'px',
                })},[_c('span',{attrs:{"data-location":JSON.stringify(items.locations),"data-name":items.fullName}},[_vm._v(_vm._s(items.fullName))]),_c('div',{staticClass:"userinfo"},[_c('div',{staticStyle:{"color":"#000"}},[_c('b',[_vm._v(" "+_vm._s(items.fullName)+" ")])]),_c('span',[_vm._v(_vm._s(items.liveInfo))])])]),_c('span',{staticClass:"circle",style:({
                  left:
                    _vm.MapNodesfrom.width -
                    items.node.x -
                    24 -
                    (_vm.headerWidth + 12) +
                    'px',
                  top: items.node.y - 186 + 186 + 'px',
                })}),_c('hr',{staticClass:"horizontal",style:({
                  left:
                    _vm.MapNodesfrom.width -
                    items.node.x -
                    items.node.childLineWidth +
                    items.node.width / 2 -
                    (_vm.headerWidth + 1 + 10) +
                    'px', //定义headerWidth = 42
                  top: items.node.childY - 200 + 14 + 186 + 'px', //201 - 0
                  width: items.node.childLineWidth - items.node.width + 'px',
                })}),_c('hr',{staticClass:"vertical",style:({
                  height: items.node.childY - items.node.y - 32 + 'px',
                  left:
                    _vm.MapNodesfrom.width -
                    items.node.x -
                    17 -
                    (_vm.headerWidth + 1 + 10) +
                    'px',
                  top: items.node.y - 152 + 186 + 'px',
                })})])}),(_vm.searchIndex == 2 && _vm.searchData.length > 0)?_c('div',{staticClass:"dataline"},_vm._l((_vm.searchData[_vm.selectIndex]
                  .paths),function(lineitems,lineindexs){return _c('div',{key:lineindexs},[_c('hr',{staticClass:"shorizontal",style:({
                    left:
                      _vm.MapNodesfrom.width -
                      lineitems.x -
                      16 -
                      _vm.getLineW(lineindexs) -
                      (_vm.headerWidth + 1 + 10) +
                      'px', //定义headerWidth = 42
                    top: lineitems.y + 185 + 'px',
                    width: _vm.getLineW(lineindexs) + 'px',
                  })}),_c('hr',{staticClass:"svertical",style:({
                    left:
                      _vm.MapNodesfrom.width -
                      lineitems.x -
                      17 -
                      (_vm.headerWidth + 1 + 10) +
                      'px',
                    top: lineitems.y + 'px',
                    height: _vm.getLineH(lineindexs) + 'px',
                  })})])}),0):_vm._e()],2),_c('div',{staticClass:"itemlin",style:({
              transform: 'translate(' + 0 + 'px,' + _vm.transformpy + 'px)',
            })},[(_vm.memberSeparations.length > 0)?_c('div',{},_vm._l((_vm.memberSeparations[
                  _vm.memberSeparations.length - 1
                ].generationNum),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item >= _vm.memberSeparations[0].generationNum),expression:"item >= memberSeparations[0].generationNum"}],key:index,staticClass:"linright",style:({
                  top: _vm.blockHeight + _vm.offsetY + 1 + 1 - 200 + 'px',
                  height: _vm.blockHeight + 'px',
                })},[_vm._v(" 第"+_vm._s(_vm.GetLineageNum(item))+"世 ")])}),0):_vm._e()])]),_c('div',{staticClass:"background-line",style:({
            width: '100%',
            transform: 'translate(' + 0 + 'px,' + _vm.transformpy + 'px)',
          })},[(_vm.memberSeparations.length > 0)?_c('div',_vm._l((_vm.memberSeparations[
                _vm.memberSeparations.length - 1
              ].generationNum),function(item,index){return _c('div',{key:index,staticClass:"background-item",style:({
                top:
                  _vm.blockHeight +
                  _vm.offsetY +
                  1 -
                  200 +
                  _vm.blockHeight * (index + 1) +
                  'px',
                width: `calc(100% + ${_vm.XValue * 2}px)`,
              })})}),0):_vm._e()]),(!_vm.LineageData)?_c('el-empty',{attrs:{"image-size":300,"description":"未加载家谱世系数据点击左边树节点加载"}}):_vm._e()],1)])]),_c('van-action-sheet',{staticStyle:{"min-height":"80%"},attrs:{"title":_vm.title,"safe-area-inset-bottom":""},model:{value:(_vm.actionShow),callback:function ($$v) {_vm.actionShow=$$v},expression:"actionShow"}},[_c('ul',{staticClass:"content"},[_c('li',[_c('span',[_vm._v("始祖：")]),_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.changeAncestorID},model:{value:(_vm.MapNodesfrom.ancestorID),callback:function ($$v) {_vm.$set(_vm.MapNodesfrom, "ancestorID", $$v)},expression:"MapNodesfrom.ancestorID"}},_vm._l((_vm.AncestorData),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.fullName,"value":item.id}})}),1)],1),_c('li',[_c('span',[_vm._v("类型：")]),_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.OptionValue),callback:function ($$v) {_vm.OptionValue=$$v},expression:"OptionValue"}},_vm._l((_vm.Option),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('li',[_c('span',[_vm._v("条件：")]),_c('el-input',{attrs:{"type":"text","placeholder":_vm.OptionValue == 1
              ? '输入人物姓名进行查询'
              : '输入两个人物名字(或父亲名字+名字)并以空格分开'},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.OptionValue == 1 ? _vm.searchClick() : _vm.relationshipClick()}},model:{value:(_vm.Keyword),callback:function ($$v) {_vm.Keyword=$$v},expression:"Keyword"}})],1),_c('li',{staticClass:"btn"},[_c('van-button',{attrs:{"type":"default"},on:{"click":function($event){_vm.OptionValue == 1 ? _vm.searchClick() : _vm.relationshipClick()}}},[_vm._v("查 询")])],1),_c('div',{staticClass:"list"},_vm._l((_vm.searchData),function(item,index){return _c('p',{key:item.id,staticClass:"item",class:{ 'select-item': _vm.selectIndex == index },on:{"click":function($event){return _vm.handleCommand({ item, index })}}},[(_vm.searchIndex == 1)?_c('span',[_vm._v(_vm._s("第" + item.generationNum + "世，父亲：" + item.fatherFullName + "+" + item.fullName))]):_vm._e(),(_vm.searchIndex == 2)?_c('span',[_vm._v(" "+_vm._s("关系" + (index + 1) + "：" + item.desc)+" ")]):_vm._e()])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }