<template>
  <div class="browse-tree">
    <div class="top">
      <div @click="$router.back()">
        <i class="el-icon-arrow-left" style="font-size: 20px"></i
        ><span>返回</span>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          height: 50px;
          align-items: center;
          justify-content: center;
        "
      >
        <span>{{ title }}</span>
        <span v-if="addGenealogyForm" style="font-size: 12px; margin-top: 5px">
          该族谱收录
          {{ addGenealogyForm.generationCount }} 世，共计
          {{ addGenealogyForm.memberCount }} 人
        </span>
      </div>
      <van-icon
        style="font-size: 22px"
        name="search"
        @click="actionShow = true"
      />
    </div>

    <div class="panel-body" ref="panelbody" v-loading="loading">
      <div class="linwrap" @touchstart.prevent="touchstart">
        <div
          class="lineage-content"
          :style="{
            transform:
              'scale(' +
              scaleValue +
              ') translate(' +
              XValue +
              'px,' +
              -YValue +
              'px)',
          }"
        >
          <div class="linshow" ref="bodyRight">
            <div
              class="lleft"
              :style="{
                transform:
                  'translate(' + transformpx + 'px,' + transformpy + 'px)',
              }"
              ref="pnlCheckingBody"
            >
              <!-- @dblclick="handelDbClick" -->
              <div
                v-for="(items, indexs) in LineageData"
                :key="indexs"
                :data-id="items.id"
              >
                <div
                  class="tree-node"
                  :class="{
                    setp:
                      (searchIndex == 1 && items.id == searchId) ||
                      (searchIndex == 2 && Include(items.id).bools),
                  }"
                  :style="{
                    position: 'absolute',
                    top: items.node.y - 186 + 186 + 'px',
                    left:
                      MapNodesfrom.width -
                      items.node.x -
                      defaultNodeSize.width -
                      (headerWidth + 7) +
                      'px',
                  }"
                >
                  <span
                    :data-location="JSON.stringify(items.locations)"
                    :data-name="items.fullName"
                    >{{ items.fullName }}</span
                  >
                  <div class="userinfo">
                    <div style="color: #000">
                      <b>
                        {{ items.fullName }}
                      </b>
                    </div>
                    <span>{{ items.liveInfo }}</span>
                  </div>
                </div>

                <span
                  class="circle"
                  :style="{
                    left:
                      MapNodesfrom.width -
                      items.node.x -
                      24 -
                      (headerWidth + 12) +
                      'px',
                    top: items.node.y - 186 + 186 + 'px',
                  }"
                ></span>
                <hr
                  class="horizontal"
                  :style="{
                    left:
                      MapNodesfrom.width -
                      items.node.x -
                      items.node.childLineWidth +
                      items.node.width / 2 -
                      (headerWidth + 1 + 10) +
                      'px', //定义headerWidth = 42
                    top: items.node.childY - 200 + 14 + 186 + 'px', //201 - 0
                    width: items.node.childLineWidth - items.node.width + 'px',
                  }"
                />
                <hr
                  class="vertical"
                  :style="{
                    height: items.node.childY - items.node.y - 32 + 'px',
                    left:
                      MapNodesfrom.width -
                      items.node.x -
                      17 -
                      (headerWidth + 1 + 10) +
                      'px',
                    top: items.node.y - 152 + 186 + 'px',
                  }"
                />
              </div>
              <!-- </div> -->
              <div
                class="dataline"
                v-if="searchIndex == 2 && searchData.length > 0"
              >
                <div
                  class=""
                  v-for="(lineitems, lineindexs) in searchData[selectIndex]
                    .paths"
                  :key="lineindexs"
                >
                  <hr
                    class="shorizontal"
                    :style="{
                      left:
                        MapNodesfrom.width -
                        lineitems.x -
                        16 -
                        getLineW(lineindexs) -
                        (headerWidth + 1 + 10) +
                        'px', //定义headerWidth = 42
                      top: lineitems.y + 185 + 'px',
                      width: getLineW(lineindexs) + 'px',
                    }"
                  />
                  <hr
                    class="svertical"
                    :style="{
                      left:
                        MapNodesfrom.width -
                        lineitems.x -
                        17 -
                        (headerWidth + 1 + 10) +
                        'px',
                      top: lineitems.y + 'px',
                      height: getLineH(lineindexs) + 'px',
                    }"
                  />
                </div>
              </div>
            </div>
            <div
              class="itemlin"
              :style="{
                transform: 'translate(' + 0 + 'px,' + transformpy + 'px)',
              }"
            >
              <div class="" v-if="memberSeparations.length > 0">
                <div
                  class="linright"
                  :style="{
                    top: blockHeight + offsetY + 1 + 1 - 200 + 'px',
                    height: blockHeight + 'px',
                  }"
                  v-for="(item, index) in memberSeparations[
                    memberSeparations.length - 1
                  ].generationNum"
                  :key="index"
                  v-show="item >= memberSeparations[0].generationNum"
                >
                  第{{ GetLineageNum(item) }}世
                </div>
              </div>
            </div>
          </div>
          <div
            class="background-line"
            :style="{
              width: '100%',
              transform: 'translate(' + 0 + 'px,' + transformpy + 'px)',
            }"
          >
            <div v-if="memberSeparations.length > 0">
              <div
                class="background-item"
                :style="{
                  top:
                    blockHeight +
                    offsetY +
                    1 -
                    200 +
                    blockHeight * (index + 1) +
                    'px',
                  width: `calc(100% + ${XValue * 2}px)`,
                }"
                v-for="(item, index) in memberSeparations[
                  memberSeparations.length - 1
                ].generationNum"
                :key="index"
              ></div>
            </div>
          </div>
          <el-empty
            v-if="!LineageData"
            :image-size="300"
            description="未加载家谱世系数据点击左边树节点加载"
          ></el-empty>
        </div>
      </div>
    </div>

    <van-action-sheet
      v-model="actionShow"
      :title="title"
      safe-area-inset-bottom
      style="min-height: 80%"
    >
      <ul class="content">
        <li>
          <span>始祖：</span>
          <el-select
            v-model="MapNodesfrom.ancestorID"
            placeholder="请选择"
            @change="changeAncestorID"
          >
            <el-option
              v-for="item in AncestorData"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span>类型：</span>
          <el-select v-model="OptionValue" placeholder="请选择">
            <el-option
              v-for="item in Option"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span>条件：</span>
          <el-input
            type="text"
            :placeholder="
              OptionValue == 1
                ? '输入人物姓名进行查询'
                : '输入两个人物名字(或父亲名字+名字)并以空格分开'
            "
            v-model="Keyword"
            @keydown.enter.native="
              OptionValue == 1 ? searchClick() : relationshipClick()
            "
          ></el-input>
        </li>
        <li class="btn">
          <van-button
            type="default"
            @click="OptionValue == 1 ? searchClick() : relationshipClick()"
            >查 询</van-button
          >
        </li>
        <div class="list">
          <p
            class="item"
            :class="{ 'select-item': selectIndex == index }"
            v-for="(item, index) in searchData"
            :key="item.id"
            @click="handleCommand({ item, index })"
          >
            <span v-if="searchIndex == 1">{{
              "第" +
              item.generationNum +
              "世，父亲：" +
              item.fatherFullName +
              "+" +
              item.fullName
            }}</span>
            <span v-if="searchIndex == 2">
              {{ "关系" + (index + 1) + "：" + item.desc }}
            </span>
          </p>
        </div>
      </ul>
    </van-action-sheet>

    <!-- <div class="rightbtn">
          <el-button :disabled="true" type="primary">始祖</el-button>
          <el-select
            v-model="MapNodesfrom.ancestorID"
            placeholder="请选择"
            @change="changeAncestorID"
          >
            <el-option
              v-for="item in AncestorData"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select v-model="OptionValue" placeholder="请选择">
            <el-option
              v-for="item in Option"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            type="text"
            :placeholder="
              OptionValue == 1
                ? '输入人物姓名进行查询'
                : '输入两个人物名字(或父亲名字+名字)并以空格分开，点查询显示人物关系'
            "
            v-model="Keyword"
            @keydown.enter.native="
              OptionValue == 1 ? searchClick() : relationshipClick()
            "
          ></el-input>
          <el-button
            type="primary"
            @click="OptionValue == 1 ? searchClick() : relationshipClick()"
            >查询<span v-if="searchData.length > 0"
              >（{{ searchData.length }}）</span
            ></el-button
          >
          <el-dropdown
            trigger="click"
            class="showData"
            @command="handleCommand"
          >
            <el-button type="primary">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :class="{ 'select-item': selectIndex == index }"
                v-for="(item, index) in searchData"
                :key="item.id"
                :command="{
                  item: item,
                  index,
                }"
              >
                <span v-if="searchIndex == 1">{{
                  "第" +
                  item.generationNum +
                  "世，父亲：" +
                  item.fatherFullName +
                  "+" +
                  item.fullName
                }}</span>
                <span v-if="searchIndex == 2">
                  {{ "关系" + (index + 1) + "：" + item.desc }}
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import { Loading } from "element-ui";
export default {
  name: "SystemBrowsetree",
  components: {},
  data() {
    return {
      actionShow: false,
      scale: 1,
      initialDistance: 0,
      initialScale: 1,
      isSearchStatus: false,
      title: "", //正题名
      addGenealogyForm: null,
      Keyword: "",
      OptionValue: 1,
      loading: false,
      AncestorData: [], //始祖
      Ancestor: "",
      Option: [
        {
          value: 1,
          label: "人物",
        },
        {
          value: 2,
          label: "人物关系",
        },
      ],
      LineageData: [], //世系数据
      memberSeparations: [], //共有几世
      searchData: [], //查询的数据
      searchId: "",
      searchIndex: 1,
      selectIndex: 0,
      // 移动参数
      multiple: 1.1, //倍数
      Width: 0, //世系宽高
      Height: 0,
      scaleValue: 1, //放大缩小值
      XValue: 0,
      YValue: 0,
      transformpx: 0, //
      transformpy: 0, //
      /* 接口参数 */
      MapNodesfrom: {
        sourceID: "",
        genealogyISBN: null,
        ancestorID: null,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      defaultNodeSize: {
        width: 32,
      },
      row: 0,
      col: 0,
      lineWidth: 2,
      headerWidth: 42,
      blockHeight: 186,
      offsetY: 15,
      loadedPages: {}, //已请求过的块
      timer: null, //防抖
    };
  },
  computed: {
    // ...mapState(["NodeIndex"]),
    getWidth() {
      return this.Width;
    },
    getBgTop(index) {
      return function (index) {
        return 185 * index;
      };
    },
    /* 数字世系转中文 */
    GetLineageNum(item) {
      return this.ChangeNumb(item);
    },
    Include(val) {
      return function (val) {
        let object = {
          bools: false,
          show: false,
        };
        if (this.searchIndex == 2 && this.searchData.length > 0) {
          object.bools = this.searchData[this.selectIndex].paths.some(
            (item, index) => {
              if (item.id == val && index != 0) {
                object.show = true;
              }
              return item.id == val;
            }
          );
        }
        return object;
      };
    },
    /* 获取关系的宽高 */
    getLineW(index) {
      return function (index) {
        let width = 0;
        const len = this.searchData[this.selectIndex].paths.length; // 8
        const pathsData = this.searchData[this.selectIndex].paths;
        if (index > 0 && index < len) {
          if (
            index === len - 2 &&
            pathsData[index].x != pathsData[index + 1].x
          ) {
            if (len == 3) {
              width = Math.abs(pathsData[index].x - pathsData[index - 1].x);
            } else {
              width = Math.abs(pathsData[index].x - pathsData[index + 1].x);
            }
          } else if (
            index === len - 1 &&
            pathsData[index].x != pathsData[index - 1].x
          ) {
            if (len == 2) {
              width = Math.abs(pathsData[index].x - pathsData[index - 1].x);
            } else {
              width = 0;
            }
          } else if (pathsData[index].y < pathsData[index - 1].y) {
            width = Math.abs(pathsData[index].x - pathsData[index - 1].x);
            if (index !== len - 1) {
              if (width == 0 && pathsData[index].y < pathsData[index + 1].y) {
                width = Math.abs(pathsData[index].x - pathsData[index + 1].x);
              }
            }
          } else {
            if (index !== len - 1) {
              width = Math.abs(pathsData[index].x - pathsData[index + 1].x);
            } else {
              width = 0;
            }
          }
        }
        return width;
      };
    },
    getLineH(index) {
      return function (index) {
        var height = 0;
        var len = this.searchData[this.selectIndex].paths.length;
        var pathsData = this.searchData[this.selectIndex].paths;
        if (index > 0 && index < len) {
          height = Math.abs(pathsData[index].y - pathsData[index - 1].y);
          if (index == len - 1 && pathsData[index].y > pathsData[index - 1].y) {
            height = 0;
          }
        }
        return height;
      };
    },
  },
  async created() {
    // this.title = this.$route.query.title;
    this.MapNodesfrom.sourceID = this.$route.params.id;
    await this.GetGenealogyDetail();
    // if (this.$route.query.keyword) {
    //   this.Keyword = this.$route.query.keyword;
    //   this.searchClick();
    // }
  },
  mounted() {
    this.$nextTick(() => {
      this.GetWidth();
    });
    window.onresize = () => {
      this.GetWidth();
    };
  },
  watch: {
    isSearchStatus(newVal) {
      if (newVal && this.$route.query.keyword) {
        this.Keyword = this.$route.query.keyword;
        this.searchClick();
      }
    },
  },
  methods: {
    // 双击
    async handelDbClick(e) {
      if (e.target.nodeName === "SPAN" && e.target.dataset.location) {
        let locations = JSON.parse(e.target.dataset.location);
        let tempArr = [];
        locations.forEach((element) => {
          tempArr.push({
            PicturePath: element.picturePath,
            PictureFileName: element.pictureFileName,
            GenealogyOCRInfoID: element.genealogyOCRInfoID,
            coordinate: element.coordinate,
            keyWord: e.target.dataset.name,
          });
        });
        try {
          const res = await this.$request({
            method: "POST",
            url: "/api/knowledgeshow/ebook/return-member-in-ebooks",
            params: {
              SourceID: this.addGenealogyForm.sourceID,
              DocumentId: this.addGenealogyForm.id,
            },
            data: tempArr,
          });
          this.$store.commit("setMemberInEbooks", res);

          let routeUrl = this.$router.resolve({
            path: "/Ebook",
            query: {
              sourceID: res[0].sourceID,
              id: res[0].documentID,
              bookId: res[0].ebookID,
              type: 133,
              memberInEbook: true,
            },
          });
          window.open(routeUrl.href, "_blank");
        } catch (error) {
          if (error.status === 403) {
            let routeUrl = this.$router.resolve({
              path: "/Ebook",
              query: {
                sourceID: this.addGenealogyForm.sourceID,
                id: this.addGenealogyForm.id,
                // bookId: res[0].ebookID,
                type: 133,
                Keyword: e.target.dataset.name,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      }
    },
    /* 移动时加载周边数据 */
    formethods() {
      // debugger;
      var x = Math.abs(this.transformpx),
        y = Math.abs(this.transformpy);
      var col = Math.floor(x / this.MapNodesfrom.width); //0
      var row = Math.floor(y / this.MapNodesfrom.height); //0
      //加载周边页面
      var minRow = Math.max(0, row - 1),
        maxRow = row + Math.ceil(1 / this.scaleValue); //
      if (row - 1 < 0) {
        maxRow += 1; //2
      }
      var minCol = Math.max(0, col - 1),
        maxCol = col + Math.ceil(1 / this.scaleValue); //
      if (col - 1 < 0) {
        maxCol += 1; //2
      }
      for (var r = minRow; r <= maxRow + 1; r++) {
        for (var c = minCol; c <= maxCol; c++) {
          this.GetMapNodes(r, c);
        }
      }
    },
    /* 缩小时加载周边数据*/
    formeSize() {
      //加载视窗内的数据
      var maxX = Math.ceil(
        (this.MapNodesfrom.width / this.scaleValue + this.transformpx) /
          this.MapNodesfrom.width
      );
      var minX = Math.ceil(this.transformpx / this.MapNodesfrom.width);
      var maxY = Math.ceil(
        (this.MapNodesfrom.height / this.scaleValue - this.transformpy) /
          this.MapNodesfrom.height
      );
      var minY = Math.ceil(-this.transformpy / this.MapNodesfrom.height);
      console.log("加载视窗内的数据", maxX, minX, maxY, minY);
      for (var r = maxY; r >= minY; r--) {
        for (var c = maxX; c >= minX; c--) {
          this.GetMapNodes(r, c);
        }
      }
    },
    //获取详情
    async GetGenealogyDetail() {
      let loading = Loading.service({
        lock: true,
        text: "加载中……",
        target: ".panel-body",
        background: "rgba(0, 0, 0, 0.7)",
      });
      await this.$request({
        url: `/api/knowledge/member/document-by-source-iD`,
        params: {
          sourceID: this.$route.params.id,
        },
        method: "GET",
      })
        .then(async (res) => {
          this.addGenealogyForm = res;
          this.title = res.title;
          await this.GetChildrenList();
        })
        .catch((e) => {})
        .finally(() => {
          this.loading = false;
          loading.close();
        });
      // this.addGenealogyForm = {};
      // this.addGenealogyForm.sourceID = "LAXX13020240000018";
      // this.title = "杨氏族谱";
      // this.GetChildrenList();
      // this.loading = false;
      // loading.close();
    },
    /* 获取根列表 */
    GetChildrenList() {
      this.$request({
        url: "/api/knowledge/member",
        method: "GET",
        params: {
          PageSize: 20,
          PageIndex: 1,
          ParentId: "ancestor",
          SourceID: this.$route.params.id,
        },
      })
        .then(async (res) => {
          this.AncestorData = res.items;
          if (res.items.length > 0) {
            this.MapNodesfrom.ancestorID = res.items[0].id;
            res.items[0].genealogyISBN
              ? (this.MapNodesfrom.genealogyISBN = res.items[0].genealogyISBN)
              : (this.MapNodesfrom.genealogyISBN = null);
            await this.GetMapNodes(0, 0);
          }
          this.isSearchStatus = true;
        })
        .catch((e) => {});
    },
    changeAncestorID(id) {
      this.scaleValue = 1;
      this.memberSeparations = [];
      this.XValue = 0;
      (this.YValue = 0),
        (this.transformpx = 0), //
        (this.transformpy = 0), //
        (this.loadedPages = {});
      this.LineageData = [];

      this.GetMapNodes(0, 0);
    },
    /* 世系树数据 */
    GetMapNodes(row, col) {
      var pageId = this.getPageId(row, col); //p0000000000
      if (this.isLoad(row, col)) {
        return false;
      }
      if (!this.MapNodesfrom.genealogyISBN && !this.MapNodesfrom.ancestorID)
        return;
      var page = {
        sourceID: this.MapNodesfrom.sourceID,
        genealogyISBN: this.MapNodesfrom.genealogyISBN,
        ancestorID: this.MapNodesfrom.ancestorID,
        row,
        col,
        x: col * this.MapNodesfrom.width,
        y: row * this.MapNodesfrom.height,
        width: this.MapNodesfrom.width,
        height: this.MapNodesfrom.height,
      };
      this.$request({
        url: "/api/knowledge/member/member-tree-map-nodes",
        method: "GET",
        params: page,
      })
        .then((res) => {
          this.loadedPages["_" + pageId] = page;
          if (res.length > 0) {
            this.LineageData.push(...res);
            res.map((member) => {
              // if (member.generationNum) {
              // }
              this.memberSeparations.push({
                name: member.fullName,
                generationNum: member.generationNum + 1,
                width: this.headerWidth,
                height: this.blockHeight,
                top: this.blockHeight + this.offsetY + 1,
              });
            });
            this.$nextTick(() => {
              this.Width = this.$refs.bodyRight.offsetWidth;
              this.Height = this.$refs.bodyRight.offsetHeight;
            });
          }
        })
        .catch((e) => {});
    },
    GetWidth() {
      this.$nextTick(() => {
        this.Width = 0; //世系宽高
        this.Height = 0;
        this.scaleValue = 1; //放大缩小值
        this.XValue = 0;
        this.YValue = 0;
        this.transformpx = 0; //
        this.transformpy = 0; //
        this.Width = this.$refs.bodyRight.offsetWidth;
        this.Height = this.$refs.bodyRight.offsetHeight;
        this.MapNodesfrom.width = this.$refs.panelbody.offsetWidth;
        this.MapNodesfrom.height = this.$refs.panelbody.offsetHeight;
      });
    },
    /* 查询人物 */
    searchClick() {
      if (!this.Keyword) return this.$message.info("请输入人物姓名进行查询");
      this.loading = true;
      this.$request({
        url: "/api/knowledge/member/members-by-name-on-map",
        method: "GET",
        params: {
          //genealogyISBN:'',
          key: this.Keyword,
          sourceID: this.addGenealogyForm.sourceID,
        },
      })
        .then((res) => {
          this.searchId = "";
          this.searchIndex = 1;
          this.searchData = res;
          this.selectIndex = 0;
          if (res.length > 0) {
            this.searchId = res[0].id;
            this.handleCommand({
              item: res[0],
              index: 0,
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        })
        .finally(() => {
          this.actionShow = false;
        });
    },
    /* 查询关系 */
    relationshipClick() {
      console.log("关系");
      if (!this.Keyword)
        return this.$message.info("请输入人物姓名或父亲的名字+名字进行查询");
      this.loading = true;
      this.$request({
        url: "/api/knowledge/member/member-relation-line",
        method: "GET",
        params: {
          //genealogyISBN:'',
          key: this.Keyword,
          sourceID: this.addGenealogyForm.sourceID,
        },
      })
        .then((res) => {
          /* this.LineageData = []
                      this.loadedPages = {} */
          this.searchIndex = 2;
          this.searchData = res;
          this.selectIndex = 0;
          if (res.length > 0) {
            this.handleCommand({
              item: res[0],
              index: 0,
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        })
        .finally(() => {
          this.actionShow = false;
        });
    },
    /* 查询的下拉菜单 */
    handleCommand(object) {
      console.log(object, "obj");
      var command = null;
      if (this.searchIndex == 1) {
        command = object.item.node;
        this.searchId = object.item.id;
      }
      if (this.searchIndex == 2) {
        command = object.item.paths[0];
        this.searchId = "";
      }
      this.selectIndex = object.index;
      var sh = command.y - 126 - this.MapNodesfrom.height / 2;
      var sw = command.x - this.MapNodesfrom.width / 2;

      if (sh >= 0) {
        this.transformpy = -sh;
      } else {
        this.transformpy = 0;
      }
      if (sw >= 0) {
        this.transformpx = sw;
      } else {
        this.transformpx = 0;
      }
      this.actionShow = false;
      this.formethods();
    },
    /* 数字世系转中文 */
    ChangeNumb(num) {
      console.log(num, "num");
      return function (num) {
        num = num.toString();
        var len = num.length;
        var aa = "";
        for (let i = 0; i < len; i++) {
          switch (num[i]) {
            case "0":
              aa += "零";
              break;
            case "1":
              aa += "一";
              break;
            case "2":
              aa += "二";
              break;
            case "3":
              aa += "三";
              break;
            case "4":
              aa += "四";
              break;
            case "5":
              aa += "五";
              break;
            case "6":
              aa += "六";
              break;
            case "7":
              aa += "七";
              break;
            case "8":
              aa += "八";
              break;
            case "9":
              aa += "九";
              break;
            default:
              aa += "";
              break;
          }
        }
        var arry = aa.split("");
        switch (arry.length) {
          case 2:
            arry[0] == "一" ? (arry[0] = "十") : (arry[0] = arry[0] + "十");
            arry[1] == "零" ? (arry[1] = "") : (arry[1] = arry[1]);
            break;
          case 3:
            arry[0] = arry[0] + "百";
            if (arry[1] == "零" && arry[2] == "零") {
              arry[1] = "";
              arry[2] = "";
            } else {
              arry[1] == "零"
                ? (arry[1] = arry[1])
                : (arry[1] = arry[1] + "十");
              arry[2] == "零" ? (arry[2] = "") : (arry[1] = arry[1]);
            }
            break;
        }

        var result = arry.join("");
        return result;
      };
    },
    // 放大/缩小
    Enlarge() {
      this.scaleValue = this.scale;
      this.GetValue();
    },
    Narrow() {
      this.scaleValue = this.scale;
      this.GetValue();
    },
    /* 移动值 */
    GetValue() {
      this.XValue =
        (this.Width - this.Width * this.scaleValue) / 2 / this.scaleValue;
      this.YValue =
        (this.Height - this.Height * this.scaleValue) / 2 / this.scaleValue;
      this.formeSize();
    },
    // 按下
    touchstart(e) {
      var that = this;
      if (e.touches.length === 2) {
        this.startPinchZoom(e);
        return;
      }
      let startX = e.touches[0].clientX;
      let startY = e.touches[0].clientY;
      let pstartX = this.transformpx;
      let pstartY = this.transformpy;

      const onMove = function (event) {
        const touch = event.touches[0];
        let clientX = touch.clientX;
        let clientY = touch.clientY;

        that.transformpx = pstartX + clientX - startX;
        that.transformpy = pstartY + clientY - startY;

        if (that.transformpx < 0) that.transformpx = 0;
        if (that.transformpy > 0) that.transformpy = 0;
      };

      const onEnd = function () {
        that.formethods();
        window.removeEventListener("touchmove", onMove);
        window.removeEventListener("touchend", onEnd);
      };

      window.addEventListener("touchmove", onMove);
      window.addEventListener("touchend", onEnd);
    },
    startPinchZoom(e) {
      this.initialDistance = this.getDistance(e.touches);
      this.initialScale = this.scale;

      const onPinchMove = (event) => {
        if (event.touches.length !== 2) return;

        const currentDistance = this.getDistance(event.touches);

        this.scale =
          this.initialScale * (currentDistance / this.initialDistance);

        if (this.scale > this.initialScale) {
          this.Enlarge();
        } else {
          this.Narrow();
        }
      };

      const onPinchEnd = () => {
        window.removeEventListener("touchmove", onPinchMove);
        window.removeEventListener("touchend", onPinchEnd);
      };

      window.addEventListener("touchmove", onPinchMove);
      window.addEventListener("touchend", onPinchEnd);
    },
    getDistance(touches) {
      const [touch1, touch2] = touches;
      const dx = touch2.clientX - touch1.clientX;
      const dy = touch2.clientY - touch1.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    },

    /* 判断是否已经加载请求过 */
    isLoad(row, col) {
      var pid = "_" + this.getPageId(row, col);
      if (this.loadedPages.hasOwnProperty(pid)) {
        return true;
      }
      return false;
    },
    getPageId(row, col) {
      return "p" + this.padding(row, 5, "0") + this.padding(col, 5, "0");
    },
    padding(number, length, prefix) {
      if (String(number).length >= length) {
        return String(number);
      }
      return this.padding(prefix + number, length, prefix);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.browse-tree {
  box-sizing: border-box;
  background-color: #fff;
  padding-top: 50px;
  height: 100vh;

  .content {
    padding: 5px 10px;

    li {
      margin: 10px 0;
      display: flex;
      align-items: center;
      span {
        white-space: nowrap;
      }
      ::v-deep .el-input input::placeholder {
        font-size: 12px;
      }
    }
    .btn {
      .van-button {
        width: 100%;
        height: 40px;
      }
    }

    .list {
      p {
        height: 40px;
        line-height: 40px;
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 5px;
    border-bottom: 1px solid #000;
    background: #fff;
    z-index: 9;

    span {
      font-size: 18px;
    }
  }

  .panel-body {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .tooltip {
      box-sizing: border-box;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 9;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      background-color: #fff;
      .item {
        padding: 6px 12px;
        cursor: pointer;
        font-size: 14px;
        i {
          margin-right: 2px;
          font-weight: 600;
          font-size: 14px;
        }
      }
      .enlarge {
        border-top: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
      }
    }
    .linwrap {
      height: 100%;
      background-color: #fff;
    }
    .linwrap::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
    }
    .lineage-content {
      position: relative;
      display: flex;
      // height: 100%;
      z-index: 1;

      .linshow {
        display: flex;
        position: relative;
        z-index: 2;
        min-width: 100%;
        .lleft {
          box-sizing: border-box;
          position: relative;
          // right: 25px;
          margin-right: 25px;
          flex: 1;
          top: -15px;
          .tree-node {
            padding: 10px 0px;
            color: red;
            /* font-weight: 600; */
            font-size: 20px;
            line-height: 21px;
            display: inline-block;
            width: 26px;
            text-align: center;
            border-radius: 16px;
            margin-top: 14px;
            z-index: 9;
            text-align: center;
            background-color: #fff;

            span {
              position: relative;
            }
            .userinfo {
              // display: none;
              box-sizing: border-box;
              overflow: hidden;
              position: absolute;
              top: 20px;
              right: 36px;
              z-index: 999;
              width: 0px;
              height: 224px;
              line-height: 24px;
              font-size: 20px;
              color: #000;
              writing-mode: tb-rl;
              letter-spacing: 2px;
              background-color: #fff;
              text-align: left;
              transition: all 0.2s linear;
              &::after {
                content: "";
                position: absolute;
                top: 3px;
                right: -12px;
                width: 0px;
                height: 0px;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left: 12px solid #fff;
              }
              &::before {
                content: "";
                position: absolute;
                top: 2px;
                right: -13px;
                width: 0px;
                height: 0px;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-left: 12px solid rgba(0, 0, 0, 0.2);
              }
            }
          }

          .tree-node:hover .userinfo {
            //   display: block;
            padding: 10px;
            //   width: fit-content;
            width: auto;
            border: 1px solid #cfcfcf;
            box-shadow: 0 0 2px 2px rgb(0 0 0 / 10%);
            transition: all 0.2s linear;
          }
          .tree-node:hover > span {
            display: inline-block;
            border-left: 2px solid red;
            cursor: pointer;
          }
          .tree-node:hover {
            z-index: 10;
          }
          .circle {
            right: -10px;
            position: absolute;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            vertical-align: middle;
            background-color: #fff;
            border-radius: 10px;
            //border: 1px solid;
            color: red;
            font-size: 14px;
            font-family: "FontAwesome";
            font-weight: normal;
            font-style: normal;
            z-index: 9;
            margin-top: 3px;
          }
          .circle:before {
            content: "\f10c";
            font-family: FontAwesome;
          }
          .horizontal {
            position: absolute;
            border: none;
            background-color: red;
            margin: 0;
            height: 2px;
            z-index: 2;
          }
          .vertical {
            position: absolute;
            border: none;
            background-color: red;
            margin: 0;
            width: 2px;
            z-index: 2;
          }
          .setp {
            color: #fff;
            background-color: #5cb85c;
          }
          .dataline {
            .shorizontal {
              position: absolute;
              border: none;
              background-color: rgb(76, 175, 80);
              margin: 0;
              height: 4px;
              z-index: 2;
            }
            .svertical {
              position: absolute;
              border: none;
              background-color: rgb(76, 175, 80);
              margin: 0;
              width: 4px;
              z-index: 2;
            }
          }
        }
        .itemlin {
          // box-sizing: border-box;
          position: relative;
          top: 0px;
          right: 0px;
          width: 35px;
          background-color: #fff;
          .linright {
            box-sizing: border-box;
            padding: 0 5px;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: 800;
            font-size: 20px;
            word-wrap: break-word;
            text-align: center;
            border-left: 1px solid #ccc;
            background-color: #fff;
            text-align: center;
            border-bottom: 1px solid #ccc;
          }
        }
      }

      .background-line {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        .background-item {
          position: absolute;
          right: 0;
          z-index: 1;
          height: 1px;
          background: #ccc;
        }
      }
    }
    .lineage-content:hover {
      cursor: move;
      -moz-user-select: none; /* Firefox私有属性 */
      -webkit-user-select: none; /* WebKit内核私有属性 */
      -ms-user-select: none; /* IE私有属性(IE10及以后) */
      -khtml-user-select: none; /* KHTML内核私有属性 */
      -o-user-select: none; /* Opera私有属性 */
      user-select: none; /* CSS3属性 */
    }
    .lineage-content::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
    }
  }
}

.select-item {
  background-color: #409eff;
  color: #fff;
}

::v-deep .el-card__body {
  padding: 0;
  height: 100%;
}
</style>